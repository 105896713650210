<template>
  <div>
    <!-- <vx-card actionable class="cardx" title="Invoice Number"> -->
    <vs-row vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card class="card-main-div" v-show="!number">
          <div slot="header">
            <h3 class="main-headd">Credit Note Number Generation</h3>
          </div>
          <div>
            <vs-row class="gaps mt-6" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                class="side-headd mb-4"
              >Customer Name</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <vs-input class="w-full  invc-input"  v-model="client_name" />
              </vs-col>
            </vs-row>
            <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                class="side-headd my-4"
              >Amount</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <vs-input class="w-full invc-input" type="number" v-model="amount" />
              </vs-col>
            </vs-row>
            <vs-row class="gaps my-8" vs-w="12">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6"
              class="side-headd mb-4">Month</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <v-select id="placeselect" class="w-full invc-input" v-model="MonthSelect" :options="months" :reduce="(option) => option.id"
                  style="height: 30px"></v-select>
              </vs-col>
            </vs-row>
            <vs-row class="gaps my-8" vs-w="12">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" class="side-headd">Details</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" class="mt-6">
              <vs-textarea vh="5" v-model="details" class="invc-txt" /></vs-col>
            </vs-row>
            <vs-row class="gaps">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <vs-button
                  class="float-center"
                  color="#0044ba"
                  @click="checkAllFields()"
                  :disabled="disableSubmit()"
                >Submit</vs-button>
              </vs-col>
            </vs-row>
          </div>
        </vs-card>
        <vx-card actionable class="cardx" v-show="number" style="width:40%;background:#0044ba;">
          <div  @click="getBack()">
          <vs-icon icon="arrow_back" class="back-icon" color="white"></vs-icon>
          </div>
          <vs-row class="gaps mt-6" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center"  vs-w="10" class="invoice-headd"><p>Generated Credit Note Number</p></vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
              <div @click="copyInvoice"><vs-icon icon="content_paste" class="copy-textt"></vs-icon></div>
              <h1>
                <input class="invoice-textt" ref="myinput" v-on:focus="$event.target.select()" type="text" v-model="invoiceNumber" disabled>
              </h1>
              <!-- <vs-input class="w-full" color="dark" placeholder="Details" style="text-align: center;" v-model="invoiceNumber" disabled /> -->
            </vs-col>
          </vs-row>
        </vx-card>
      </vs-col>
    </vs-row>
    <!-- </vx-card> -->
  </div>
</template>

<script>
// import EventBus from "../eventbus.js";
import constants from "../../constants.json";
import vSelect from "vue-select";
import axios from "axios";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      all_months:['January','February','March','April','May','June','July','August','September','October','November','December'],
      months:[],
      MonthSelect: null,
      client_name: "",
      amount: null,
      details: "",
      CitySelect: "",
      invoiceNumber: "",
      number: false,
      cities: [
        "Ahmedabad",
        "Bengaluru",
        "Chennai",
        "Delhi",
        "Ernakulam",
        "Hyderabad",
        "Kolkata",
        "Mumbai",
        "Pune",
      ],
    };
  },
  methods: {
    getBack(){
      this.$vs.loading()
      setTimeout( ()=> {
        this.$vs.loading.close()
      }, 700);
      this.number = false
      this.client_name= "",
      this.amount= null,
      this.details= "",
      this.invoiceNumber= "";
      const d = new Date();
      this.MonthSelect = d.getMonth()+1;
    },
    getMonths(){
      const d = new Date();
      let val = d.getDate();
      if (val < 11) {
        let curr_month = d.getMonth()
        let obj1;
        if (curr_month-1 == -1) {
          obj1 = {id: curr_month,label:this.all_months[11]}
        }else{
          obj1 = {id: curr_month,label:this.all_months[curr_month-1]}
        }
        let obj = {id: curr_month+1,label:this.all_months[curr_month]}
        this.months.push(obj)
        this.months.push(obj1)
      }else{
        let curr_month = d.getMonth()
        let obj = {id: curr_month+1,label:this.all_months[curr_month]}
        this.months.push(obj)
      }
    },
    disableSubmit(){
      if (this.amount <= 0 || Number(this.amount) == 0) {
        return true
      }else return false
    },
    copyInvoice(){
      this.$refs.myinput.disabled = false
      this.$refs.myinput.focus();
      document.execCommand('copy');
      this.$vs.notify({
        title:'copied',
        text:'copied to clipboard',
        color:'success'
      })
      this.$refs.myinput.disabled = true
    },
    InvoiceNumber() {
      this.$vs.loading();
      let obj = {
        type:'creditMemo',
        customer_name: this.client_name,
        amount: this.amount,
        details: this.details,
        city: "CreditMemo",
        month: this.MonthSelect
      };
      console.log(obj);
      const url = `${constants.MILES_FINANCE}getCustomInvoiceNoFromAccountsServer`;
      axios
        .get(url, {
          params:obj,headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("result", response.data);
          if (response.data.status == 'failed') {
            let msg = response.data.message
              if (msg.includes('UnAuthorized')) {
              this.$vs.notify({
                title:'UnAuthorized',
                text:'You Are UnAuthorized To Perform This Action',
                color:'danger'
              })
            }
          }else{
            this.handleNotification(response);
            this.number = true;
            this.invoiceNumber = response.data.invoice_number;
          }
          
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    checkAllFields(){
      if (this.MonthSelect != null && this.client_name != "" && this.details != "") {
        this.InvoiceNumber()
      }else{
        this.$vs.notify({
        title:'Field is required',
        text:'Please fill out all fields to proceed',
        color:'danger'
      })
      }
    },
  },
  mounted(){
    this.getMonths()
    const d = new Date();
    this.MonthSelect = d.getMonth()+1;
  },
  watch:{
    client_name(val){
      if (val.length > 32) {
        this.$vs.notify({
          title:'Error',
          text:'The customer name must not be greater than 32 characters.',
          color:'danger'
        })
      }
    }
  }
};
</script>

<style>
.vs--searchable .vs__dropdown-toggle {
  height: 37px;
}
.side-headd{
  font-family: 'montserrat';
  font-size: 15px;
  line-height: 1.25px;
  font-weight: 700;
  letter-spacing: 0.15px;
}
.cardx{
width: 100%;
margin: 0 auto !important;
}
.main-headd , .cardx{
  font-family: 'montserrat';
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.25px;
  text-align: center;
  color:#fff
}
.card-main-div {
  border-radius: 15px;
  box-shadow: 3.5px 6px rgb(0 0 0 / 0.04);
  width:475px;height:98%
}
.card-main-div > .vs-card--header{
  background-color: #0044ba;
  border-radius: 12px 12px 0 0;
}
.invc-input .vs-input--input:focus{
  border: 1px solid #cccccc !important
}
.invc-input>.vs__dropdown-menu{
  border: 1px solid #cccccc !important
}
.invc-input>.vs__dropdown-menu .vs__dropdown-option{
  font-size: 1rem;
}
.invoice-headd{
  font-family: 'montserrat';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.75px;
  color: rgba(255, 255, 255, 0.486);
}
.invoice-textt{
  font-family: 'montserrat';
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #fff;
  background-color: #0044ba;
  border: 0px;
}
.copy-textt{
  top:10px;
  padding: 4px;
  position: absolute;
  margin-left: 85%;
  color: rgba(255, 255, 255, 0.466) !important;
}
.back-icon{
  color: rgba(255, 255, 255, 0.466) !important;
  display:flex;
  justify-content:flex-start;
  margin: -15px 0 0 -12px;
  font-size: 26px !important;
  font-weight: 900;
  position:absolute
}
.back-icon:hover{
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: rgba(255, 255, 255, 0.116) !important;
  border-radius: 7px;
}
.copy-textt:hover{
  background-color: rgba(255, 255, 255, 0.116);
  border-radius: 5px;
}
@media only screen and (max-width: 1625px) {
    .copy-textt{
      margin-left: 83% !important;
    }
}
@media only screen and (max-width: 1425px) {
    .copy-textt{
      margin-left: 80% !important;
    }
    .invoice-headd{
      font-size: 13px;
    }
}
@media only screen and (max-width: 1250px) {
    .invoice-headd{
      font-size: 12px;
    }
}
</style>
